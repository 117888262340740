<template>

  <div>
    <b-card
      class="mb-0"
    >
      <div class="mb-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h4 class="card-title mb-0 pt-50">
              Jobs</h4>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              v-if="employers.length > 1"
              label="Parent Type"
              label-cols-md="5"
              class="w-50 text-right mb-0"
            >
              <v-select
                v-model="parent"
                :options="employers"
                :reduce="item => item"
                :clearable="false"
                placeholder="Select"
                @input="onTypeChanged()"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </div>

      <vue-good-table
        :columns="columns"
        :rows="jobsResponse.jobs"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >

        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Link -->
          <span v-if="props.column.field === 'cellRendererLink'">
            <span v-if="!jobsResponse.employee"><router-link :to="{ name: 'jobs-view', params: { job_id: props.row.id }, query: { name: props.row.job_title, type: type } }">{{ props.row.position_id }}</router-link></span>
            <span v-if="jobsResponse.employee"><router-link :to="{ name: 'jobs-view-employee', params: { job_id: props.row.id }, query: { name: props.row.job_title, type: type } }">{{ props.row.position_id }}</router-link></span>
          </span>

          <!-- Column: Link -->
          <span v-if="props.column.field === 'cellRendererLink2'">
            <span v-if="!props.row.employee"><router-link :to="{ name: 'candidates', params: { job_id: props.row.id }, query: { name: props.row.job_title, type: type, employerId: employerId } }">{{ props.row.candidates }}</router-link></span>
          </span>

          <!-- Column: Link -->
          <span v-if="props.column.field === 'cellRendererLink3'">
            <span v-if="!jobsResponse.employee"><router-link
              :to="{ name: 'existing-candidates', params: { job_id: props.row.id }, query: { name: props.row.job_title, type: type } }"
              class="btn btn-primary"
            >Apply</router-link></span>
            <span v-if="jobsResponse.employee"><router-link
              :to="{ name: 'refer-candidates-new', params: { job_id: props.row.id }, query: { name: props.row.job_title, type: type } }"
              class="btn btn-primary"
            >Apply / Refer</router-link></span>
          </span>

          <!-- Column: Currency -->
          <span v-if="props.column.field === 'cellRendererCurrency'">
            <span>{{ props.row.bill_rate| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
          </span>

          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'action'"
            class="btn-action"
          >
            <b-button
              variant="primary"
              :to="{ name: 'jobs-apply'}"
            >
              <span class="text-nowrap">Apply</span>
            </b-button>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-end flex-wrap">
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="jobsResponse.count"
                first-number
                last-number
                align="right"
                :per-page="1"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge, BPagination, BFormGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import User from '@/js/user'
import Jobs from '@/js/jobs'
import Auth from '@/auth/authService'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BPagination,
    BFormGroup,
    VueGoodTable,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      employerId: null,
      employers: [],
      jobsResponse: {
        employee: false,
        jobs: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Position ID',
          field: 'cellRendererLink',
          sortable: false,
        },
        {
          label: 'Job Title',
          field: 'job_title',
          sortable: false,
        },
        {
          label: 'Bill Rate',
          field: 'cellRendererCurrency',
          width: '160px',
          sortable: false,
        },
        {
          label: 'Visa Required',
          field: 'visa_types',
          sortable: false,
        },
        {
          label: 'Experience',
          field: 'experience',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Submitted Candidates',
          field: 'cellRendererLink2',
          width: '170px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'cellRendererLink3',
          width: '170px',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Jobs.getJobs(this)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.jobsResponse.count = this.$store.state.query.page
    this.employers = User.profile().job_parent
    this.type = this.employers[0].parent_type_id
    this.employerId = this.employers[0].parent_id
    if (User.profile().job_exist) {
      Jobs.getJobs(this)
    } else if (User.profile().invoice_exist) {
      window.location.hash = '#/invoices'
    } else if (User.profile().timesheet_exist) {
      window.location.hash = '#/timesheets'
    } else {
      Auth.logout()
    }
  },
  methods: {
    onTypeChanged() {
      this.jobs = []
      this.recordsCount = 1
      this.pageNum = 1
      this.type = this.parent.parent_type_id
      this.employerId = this.parent.parent_id
      Jobs.getJobs(this)
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setJobs(jobsResponse) {
      this.jobsResponse = jobsResponse
      this.$set(this.columns[3], 'hidden', jobsResponse.employee)
      this.$set(this.columns[6], 'hidden', jobsResponse.employee)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
